const DistributionRevenus = {
  bareme: [
    {
      revenu: 613,
      percentile: 100 - 10
    }, {
      revenu: 983,
      percentile: 100 - 20
    }, {
      revenu: 1253,
      percentile: 100 - 30
    }, {
      revenu: 1498,
      percentile: 100 - 40
    }, {
      revenu: 1733,
      percentile: 100 - 50
    }, {
      revenu: 1777,
      percentile: 100 - 52
    }, {
      revenu: 1823,
      percentile: 100 - 54
    }, {
      revenu: 1874,
      percentile: 100 - 56
    }, {
      revenu: 1923,
      percentile: 100 - 58
    }, {
      revenu: 1977,
      percentile: 100 - 60
    }, {
      revenu: 2037,
      percentile: 100 - 62
    }, {
      revenu: 2095,
      percentile: 100 - 64
    }, {
      revenu: 2160,
      percentile: 100 - 66
    }, {
      revenu: 2226,
      percentile: 100 - 68
    }, {
      revenu: 2297,
      percentile: 100 - 70
    }, {
      revenu: 2372,
      percentile: 100 - 72
    }, {
      revenu: 2457,
      percentile: 100 - 74
    }, {
      revenu: 2543,
      percentile: 100 - 76
    }, {
      revenu: 2643,
      percentile: 100 - 78
    }, {
      revenu: 2751,
      percentile: 100 - 80
    }, {
      revenu: 2879,
      percentile: 100 - 82
    }, {
      revenu: 3017,
      percentile: 100 - 84
    }, {
      revenu: 3172,
      percentile: 100 - 86
    }, {
      revenu: 3367,
      percentile: 100 - 88
    }, {
      revenu: 3606,
      percentile: 100 - 90
    }, {
      revenu: 3938,
      percentile: 100 - 92
    }, {
      revenu: 4402,
      percentile: 100 - 94
    }, {
      revenu: 5082,
      percentile: 100 - 96
    }, {
      revenu: 6543,
      percentile: 100 - 98
    }, {
      revenu: 8679,
      percentile: 100 - 99
    }, {
      revenu: 11596,
      percentile: 100 - 99.5
    }, {
      revenu: 22171,
      percentile: 100 - 99.9
    }, {
      revenu: 61942,
      percentile: 100 - 99.99
    }
  ]

}

export default DistributionRevenus
