import * as CSG from './parametres/CSGexistant.mjs'
import { appliquerBareme } from './utils.mjs'

export function assietteCSG (revenuBrut) {
  return appliquerBareme(
    revenuBrut,
    [0, CSG.PLAFOND_ASSIETTE_REDUITE],
    [CSG.TAUX_ASSIETTE_REDUITE, 1]
  )
}

export function montantCSG (revenuBrut) {
  const assiette = assietteCSG(revenuBrut)

  return assiette * (CSG.TAUX_PLEIN + CSG.TAUX_CRDS)
}

export function montantCSGDeductible (revenuBrut) {
  const assiette = assietteCSG(revenuBrut)
  return assiette * CSG.TAUX_DEDUCTIBLE
}
