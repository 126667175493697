import * as CSG from './parametres/CSGpropose.mjs'
import * as CSGexistante from './parametres/CSGexistant.mjs'
import { assietteCSG } from './CSGexistant.mjs'
import { appliquerBareme } from './utils.mjs'

export function appliquerBaremeCSG (montant) {
  return appliquerBareme(montant, CSG.LIMITES_TRANCHES, CSG.TAUX_MARGINAUX)
}

export function montantCSG (revenuBrut) {
  const assiette = assietteCSG(revenuBrut)
  return appliquerBaremeCSG(assiette) + CSGexistante.TAUX_CRDS * assiette
}
