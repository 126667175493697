import * as IRP from './parametres/IRPpropose.mjs'
import * as IRPExistant from './parametres/IRPexistant.mjs'
import { appliquerBareme } from './utils.mjs'
import { revenuFiscalDeReference } from './revenus.mjs'
import { calculerQuotientFamilial, montantDecote } from './IRPexistant.mjs'

export function appliquerBaremeIRP (montant) {
  return appliquerBareme(montant, IRP.LIMITES_TRANCHES, IRP.TAUX_MARGINAUX)
}

export function montantIRP (revenuDeclare, coupleMarie, nbEnfants) {
  const rfr = revenuFiscalDeReference(revenuDeclare)

  const irpAvantDecote = calculerQuotientFamilial(
    rfr,
    coupleMarie,
    nbEnfants,
    appliquerBaremeIRP
  )

  const irpFinal = irpAvantDecote - montantDecote(irpAvantDecote)

  return irpFinal >= IRPExistant.SEUIL_RECOUVREMENT ? irpFinal : 0
}
