import { sum, max, lerp } from '../utils.mjs'
import { montantIRP as montantIRPExistant } from './IRPexistant.mjs'
import { montantIRP as montantIRPPropose } from './IRPpropose.mjs'
import { montantCSG as montantCSGExistant } from './CSGexistant.mjs'
import { montantCSG as montantCSGPropose } from './CSGpropose.mjs'
import { revenuDeclare, revenuBrut } from './revenus.mjs'

export function simuler (revenusNets, nbEnfants) {
  const revenusBruts = revenusNets.map(revenuBrut)

  const coupleMarie = revenusBruts.length > 1
  const declare = sum(revenusBruts.map(revenuDeclare))

  const csgExistant = sum(revenusBruts.map(montantCSGExistant))
  const csgPropose = sum(revenusBruts.map(montantCSGPropose))

  const irpExistant = montantIRPExistant(declare, coupleMarie, nbEnfants)
  let irpPropose = montantIRPPropose(declare, coupleMarie, nbEnfants)

  irpPropose = ajustementIRP({
    revenusNets,
    irpExistant,
    csgExistant,
    irpPropose,
    csgPropose,
    coupleMarie,
    nbEnfants
  })

  return {
    current: {
      IR: irpExistant,
      CSG: csgExistant,
      total: irpExistant + csgExistant
    },
    revolution: {
      IR: irpPropose,
      CSG: csgPropose,
      total: irpPropose + csgPropose
    },
    gain: irpExistant + csgExistant - irpPropose - csgPropose
  }
}

export function ajustementIRP ({
  revenusNets,
  irpExistant,
  csgExistant,
  irpPropose,
  csgPropose,
  coupleMarie,
  nbEnfants
}) {
  const gain = irpExistant - irpPropose + csgExistant - csgPropose

  const perdantsIndus = gain < 0 &&
        ((!coupleMarie && sum(revenusNets) <= (4000 * 12)) ||
         (coupleMarie && sum(revenusNets) <= (5000 * 12)) ||
         (coupleMarie && max(revenusNets) <= (4000 * 12)))

  const gagnantsIndus = gain > 0 &&
        sum(revenusNets) > (5500 * 12) &&
        max(revenusNets) > (4000 * 12)

  if (perdantsIndus) {
    const valeurAjustee = irpExistant + csgExistant - csgPropose
    irpPropose = valeurAjustee > 0 ? valeurAjustee * 0.95 : valeurAjustee * 1.05
  } else if (gagnantsIndus) {
    irpPropose += 2 * gain
  }

  const lissageCelibatairePlus2Enfants = !coupleMarie &&
        nbEnfants > 2 &&
        revenusNets[0] > (4000 * 12) &&
        revenusNets[0] < (5000 * 12)

  if (lissageCelibatairePlus2Enfants) {
    const valeurAjustee = irpExistant + csgExistant - csgPropose
    const t = (revenusNets[0] / 12 - 4000) / 1000
    irpPropose = lerp(valeurAjustee, irpPropose, t)
  }

  return irpPropose
}
