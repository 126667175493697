import { appliquerBareme } from './utils.mjs'
import { nombreDePartsFiscalesEnfants } from './parametres/partsFiscales.mjs'
import { revenuFiscalDeReference } from './revenus.mjs'
import * as IRP from './parametres/IRPexistant.mjs'

export function appliquerBaremeIRP (montant) {
  return appliquerBareme(
    montant,
    IRP.LIMITES_TRANCHES,
    IRP.TAUX_MARGINAUX
  )
}

export function calculerQuotientFamilial (
  rfr, coupleMarie, nbEnfants, appliquerBareme
) {
  const partsAdulte = 1 + coupleMarie
  const partsEnfants = nombreDePartsFiscalesEnfants(coupleMarie, nbEnfants)
  const plafondEconomieQuotientFamilial = IRP.PLAFOND_DEMI_PART * partsEnfants * 2

  const partsFiscales = partsAdulte + partsEnfants

  const quotientFamilial = rfr / partsFiscales
  const quotientConjugal = rfr / partsAdulte

  const impotBrutQF = appliquerBareme(quotientFamilial) * partsFiscales
  const impotBrutQC = appliquerBareme(quotientConjugal) * partsAdulte

  const economieQuotientFamilial = Math.min(
    impotBrutQC - impotBrutQF,
    plafondEconomieQuotientFamilial
  )

  return impotBrutQC - economieQuotientFamilial
}

export function montantDecote (
  irpAvantDecote, coupleMarie
) {
  return coupleMarie
    ? Math.max(0, IRP.MONTANT_DECOTE_COUPLE - IRP.TAUX_DECOTE * irpAvantDecote)
    : Math.max(0, IRP.MONTANT_DECOTE_CELIBATAIRE - IRP.TAUX_DECOTE * irpAvantDecote)
}

export function montantIRP (
  revenuDeclare,
  coupleMarie,
  nbEnfants
) {
  const rfr = revenuFiscalDeReference(revenuDeclare)

  const irpAvantDecote = calculerQuotientFamilial(
    rfr,
    coupleMarie,
    nbEnfants,
    appliquerBaremeIRP
  )

  const irpFinal = irpAvantDecote - montantDecote(irpAvantDecote)

  return irpFinal >= IRP.SEUIL_RECOUVREMENT ? irpFinal : 0
}
