import { rightBinarySearch } from '../utils.mjs'
import { montantCSGDeductible } from './CSGexistant.mjs'
import * as IRP from './parametres/IRPexistant.mjs'

const RATIOS_NET_VERS_BRUT = [
  1.27, 1.25, 1.2, 1.15, 1.1]

const LIMITES_NET_VERS_BRUT = [
  9000, 30000, 50000, 500000
].map(i => i * 12)

export function revenuFiscalDeReference (revenuDeclare) {
  const abattement = Math.min(
    Math.max(
      IRP.ABATTEMENT_FRAIS_PROFESSIONNELS_PLANCHER,
      IRP.ABATTEMENT_TAUX * revenuDeclare
    ),
    IRP.ABATTEMENT_FRAIS_PROFESSIONNELS_PLAFOND
  )
  return revenuDeclare - abattement
}

export function revenuDeclare (revenuBrut) {
  return revenuBrut - montantCSGDeductible(revenuBrut)
}

export function revenuBrut (revenuNet) {
  const ratio = RATIOS_NET_VERS_BRUT[rightBinarySearch(
    LIMITES_NET_VERS_BRUT,
    revenuNet
  )]

  return ratio * revenuNet
}
