import { ReduceStore } from 'flux/utils'
import AppDispatcher from '../dispatcher/AppDispatcher'
import Constants from './../constants/Constants'

import { simuler } from '../data/comparaison.mjs'

import DistributionRevenus from '../data/DistributionRevenus'

// Catch: State must be immutable
class SimuStore extends ReduceStore {
  percentageRicher (net, couple) {
    const bareme = DistributionRevenus.bareme
    let percentage = 0
    for (const item of bareme) {
      if ((net / (1 + couple)) > item.revenu) {
        percentage = item.percentile
      } else {
        break
      }
    }
    return percentage
  }

  // salaires = { person1: x, person2: x }
  generateSeries (salaires, retraite, chomage, couple, nbEnfants) {
    let revenusNets
    if (couple) {
      revenusNets = [salaires.person1 * 12, salaires.person2 * 12]
    } else {
      revenusNets = [salaires.person1 * 12]
    }

    return simuler(revenusNets, nbEnfants)
  }

  getInitialState () {
    const net = 0
    const retraite = 0
    const chomage = 0
    const couple = 0
    const nbEnfants = 0

    return {
      theme: Constants.Theme.DESIGNED,
      defaultNet: 1600,
      // defaultNet: 2800,
      net,
      retraite: 0,
      chomage: 0,
      percentile: this.percentageRicher(0, couple),
      isMarried: couple,
      numberOfChildren: nbEnfants,
      results: this.generateSeries(net, retraite, chomage, couple, nbEnfants)
    }
  }

  reduce (state, action) {
    switch (action.actionType) {
      case Constants.Action.NET_CHANGED:
        return {
          ...state,
          net: action.net,
          percentile: this.percentageRicher(action.net, state.isMarried),
          results: this.generateSeries(action.net, 0, 0, state.isMarried, state.numberOfChildren)
        }

      case Constants.Action.RETRAITE_CHANGED:
        return {
          ...state,
          retraite: action.retraite,
          results: this.generateSeries(state.net, action.retraite, state.chomage, state.isMarried, state.numberOfChildren)
        }

      case Constants.Action.CHOMAGE_CHANGED:
        return {
          ...state,
          chomage: action.chomage,
          results: this.generateSeries(state.net, state.retraite, action.chomage, state.isMarried, state.numberOfChildren)
        }

      case Constants.Action.MARITAL_STATUS_CHANGED:
        return {
          ...state,
          isMarried: action.isMarried,
          percentile: this.percentageRicher(state.net, action.isMarried),
          results: this.generateSeries(state.net, state.retraite, state.chomage, action.isMarried, state.numberOfChildren)
        }

      case Constants.Action.NUMBER_OF_CHILDREN_CHANGED:
        return {
          ...state,
          numberOfChildren: action.numberOfChildren,
          results: this.generateSeries(state.net, state.retraite, state.chomage, state.isMarried, action.numberOfChildren)
        }

      case Constants.Action.THEME_CHANGED:
        return {
          theme: action.theme,
          ...state
        }

      default:
        return state
    }
  }
}

export default new SimuStore(AppDispatcher)
