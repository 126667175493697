export const LIMITES_TRANCHES = [
  0,
  600,
  900,
  1300,
  1600,
  1900,
  2300,
  2700,
  3100,
  3600,
  5000,
  8000,
  15000,
  33000
].map(t => t * 12)

export const TAUX_MARGINAUX = [
  0.0300,
  0.0400,
  0.0650,
  0.0850,
  0.1050,
  0.1250,
  0.2300,
  0.2500,
  0.2900,
  0.3200,
  0.4000,
  0.5300,
  0.6000,
  0.7000
]
