export const LIMITES_TRANCHES = [
  0,
  500,
  1000,
  1400,
  1800,
  2200,
  2700,
  3400,
  4200,
  4990,
  6000,
  8000,
  15000,
  33000
].map(l => l * 12)

export const TAUX_MARGINAUX = [
  0.0600,
  0.0650,
  0.0675,
  0.0750,
  0.0800,
  0.0850,
  0.0920,
  0.1250,
  0.1364,
  0.1500,
  0.1600,
  0.1700,
  0.1800,
  0.1900
]
