// Dernière actualisation : données valables pour l'exercice 2023

// Tranches et taux marginaux
export const LIMITES_TRANCHES = [
  11294,
  28797,
  82341,
  177106
]

export const TAUX_MARGINAUX = [
  0.11,
  0.3,
  0.41,
  0.45
]

// Décote
export const SEUIL_DECOTE_CELIBATAIRE = 1929
export const SEUIL_DECOTE_COUPLE = 3191

export const TAUX_DECOTE = 0.4525
export const MONTANT_DECOTE_CELIBATAIRE = 873
export const MONTANT_DECOTE_COUPLE = 1444

// Plafonnement du quotient familial
export const PLAFOND_DEMI_PART = 1759
export const PLAFOND_QUART_PART = 880
export const PLAFOND_PREMIERE_PART_CELIBATAIRE = 4149

// taux de l'abattement
export const ABATTEMENT_TAUX = 0.1

// Abattement pour frais professionnels
export const ABATTEMENT_FRAIS_PROFESSIONNELS_PLANCHER = 495
export const ABATTEMENT_FRAIS_PROFESSIONNELS_PLAFOND = 14171

// Abattement sur retraites
export const ABATTEMENT_PENSION_RETRAITE_PLANCHER_CELIBATAIRE = 442
export const ABATTEMENT_PENSION_RETRAITE_PLANCHER_COUPLE = 442 + 442
export const ABATTEMENT_PENSION_RETRAITE_PLAFOND_FOYER = 4321

// Seuil de recouvrement
export const SEUIL_RECOUVREMENT = 61
