export function nombreDePartsFiscalesEnfants (coupleMarie, nbEnfants) {
  // une demi-part pour chacun des 2 premiers enfants
  // une part pour chaque enfant à partir du 3ème
  let nbParts = 0.5 * nbEnfants + 0.5 * Math.max(0, nbEnfants - 2)

  // une demi-part supplémentaire pour les enfants isolés
  if (!coupleMarie && nbEnfants > 0) {
    nbParts += 0.5
  }

  return nbParts
}
