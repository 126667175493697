import { leftBinarySearch, sum } from '../utils.mjs'

export function appliquerBareme (montant, tranches, taux) {
  const indexMarginal = leftBinarySearch(tranches, montant) - 1

  if (indexMarginal < 0) {
    return 0
  }

  const largeurTranchesEntieres = [...Array(indexMarginal).keys()]
    .map((i) => tranches[i + 1] - tranches[i])

  const montantTranchesEntieres = sum(
    largeurTranchesEntieres.map((l, i) => l * taux[i])
  )

  const montantTrancheMarginale = (montant - tranches[indexMarginal]) * taux[indexMarginal]

  return montantTranchesEntieres + montantTrancheMarginale
}
