const colorDefault = '#333'
const violetNFP = '#9A36E0'
const roseNFP = '#E6255B'
const yellowNFP = '#FFEC00'
const greenNFP = '#4BB166'
const fontInter = 'Inter'
const fontSofia = 'Sofia-Pro'
const collapse = '1050px'
const boxShadow =
  '0px 0px 2px rgba(0, 0, 0, 0.5), 0px 3px 3px rgba(0, 35, 44, 0.1)'

const violetNFPLight = '#B56BE8'
const roseNFPDark = '#981137'

export const style = {
  colorDefault,
  violetNFP,
  roseNFP,
  greenNFP,
  yellowNFP,
  violetNFPLight,
  roseNFPDark,
  fontInter,
  fontSofia,
  collapse,
  boxShadow
}

export const THEMES = {
  themeDefault: {
    color: roseNFP
  },
  themeHeritage: {
    color: greenNFP
  }
}
