export function binarySearch (array, pred) {
  let lo = -1; let hi = array.length
  while (1 + lo < hi) {
    const mi = lo + ((hi - lo) >> 1)
    if (pred(array[mi])) {
      hi = mi
    } else {
      lo = mi
    }
  }
  return hi
}

export function rightBinarySearch (array, value) {
  return binarySearch(array, other => value < other)
}

export function leftBinarySearch (array, value) {
  return binarySearch(array, other => value <= other)
}

export function sum (array) {
  return array.reduce((acc, val) => acc + val, 0)
}

export function max (array) {
  return array.reduce((acc, val) => Math.max(acc, val))
}

export function lerp (a, b, t) {
  return a + (b - a) * t
}
